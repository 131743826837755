import "./App.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Offcanvas from "react-bootstrap/Offcanvas";
import logo from "./assets/img/logo.svg";
import * as Icon from "react-bootstrap-icons";
import { ListGroup } from "react-bootstrap";
import "./css/custom.css";

function App() {
  return (
    <Container id="home" className="background">
      <Navbar bg="white" expand="lg" className="d-none d-md-block">
        <Container>
          <Navbar.Brand href={process.env.REACT_APP_REEBIT_URL}>
            <img
              src={logo}
              width="177"
              height="50"
              className="d-inline-block align-top"
              alt="Reebit logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="pe-2 ">
              <Nav.Link
                id="newLandingRegistrationTopMenuRegistrationLink"
                href={`${process.env.REACT_APP_REEBIT_URL}register`}
                className="link-menu"
              >
                Registrarme
              </Nav.Link>
              <Nav.Link
                id="newLandingRegistrationTopMenuLoginLink"
                href={`${process.env.REACT_APP_REEBIT_URL}login`}
                className="link-menu"
              >
                Iniciar sesión
              </Nav.Link>
            </Nav>
            <Button
              id="newLandingRegistrationTopMenuPublishService"
              href={process.env.REACT_APP_REEBIT_URL}
              className="publish-service"
              variant="dark"
            >
              Publicar Servicio
            </Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/*NAVBAR MOBILE */}
      <Navbar key="lg" bg="white" expand="lg" className="mb-3 d-md-none">
        <Container fluid>
          <Navbar.Brand href={process.env.REACT_APP_REEBIT_URL}>
            <img
              src={require("./assets/img/iso_logo.png")}
              width="177"
              height="50"
              className="d-inline-block align-top"
              alt="Reebit logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-lg`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title
                id={`offcanvasNavbarLabel-expand-lg`}
              ></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link
                  id="newLandingRegistrationTopMenuRegistrationLink"
                  href={`${process.env.REACT_APP_REEBIT_URL}register`}
                  className="link-menu"
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#31353C",
                  }}
                >
                  <Icon.EmojiSmile className="me-3" fontSize="1rem" />
                  Registrarme
                </Nav.Link>
                <Nav.Link
                  id="newLandingRegistrationTopMenuLoginLink"
                  href={`${process.env.REACT_APP_REEBIT_URL}register`}
                  className="link-menu"
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#31353C",
                  }}
                >
                  <Icon.Person className="me-3" fontSize="1rem" />
                  Iniciar sesión
                </Nav.Link>
              </Nav>
              <div className="d-grid gap-2 px-2 pt-4">
                <Button
                  id="newLandingRegistrationTopMenuPublishService"
                  href={process.env.REACT_APP_REEBIT_URL}
                  className="publish-service"
                  variant="dark"
                >
                  Publicar Servicio
                </Button>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>

      <Container>
        <Row id="homeRow">
          <Col
            md={6}
            xs={{ span: 12, order: 2 }}
            className="d-flex justify-content-center align-items-center"
          >
            {/* HOME ROW CARD PANTALLA GRANDE */}
            <Card
              className="d-none d-md-block"
              style={{ width: "36.25em", background: "none", border: "none" }}
            >
              <Card.Body>
                <Card.Title>
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "4rem",
                      lineHeight: "120%",
                    }}
                  >
                    Encuentra el servicio que estás buscando
                  </p>
                </Card.Title>
                <Card.Text>
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "1.5625rem",
                      lineHeight: "150%",
                    }}
                  >
                    Encuentra a más de 7.000 profesionales en la red de
                    clasificados más grande de Chile.
                  </p>
                </Card.Text>
                <ButtonGroup className="me-3">
                  <Button
                    variant="primary"
                    className="register-button"
                    href={`${process.env.REACT_APP_REEBIT_URL}register`}
                    id="newLandingRegistrationServiceSearchingPublishServiceBtn"
                  >
                    Registrarme
                  </Button>
                </ButtonGroup>
                <ButtonGroup className="me-3">
                  <Button
                    id="newLandingRegistrationServiceSearchingMeetReebitBtn"
                    href={process.env.REACT_APP_REEBIT_URL}
                    variant="outline-primary"
                    className="about-button"
                  >
                    Conocer Reebit
                  </Button>
                </ButtonGroup>
              </Card.Body>
            </Card>
            {/* HOME ROW CARD PANTALLA PEQUEÑA */}
            <Card
              className="d-md-none text-align-center"
              style={{ width: "20.563rem", background: "none", border: "none" }}
            >
              <Card.Body>
                <Card.Title>
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "2.125rem",
                      lineHeight: "120%",
                      textAlign: "center",
                    }}
                  >
                    Encuentra el servicio que estás buscando
                  </p>
                </Card.Title>
                <Card.Text>
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "0.75rem",
                      lineHeight: "150%",
                      textAlign: "center",
                    }}
                  >
                    Encuentra a más de 7.000 profesionales en la red de
                    clasificados más grande de Chile.
                  </p>
                </Card.Text>
                <Row style={{ marginBottom: "20px" }}>
                  <Button
                    variant="primary"
                    className="register-button"
                    href={`${process.env.REACT_APP_REEBIT_URL}register`}
                    id="newLandingRegistrationServiceSearchingPublishServiceBtn"
                  >
                    Registrarme
                  </Button>
                </Row>
                <Row>
                  <Button
                    id="newLandingRegistrationServiceSearchingMeetReebitBtn"
                    href={process.env.REACT_APP_REEBIT_URL}
                    variant="outline-primary"
                    className="about-button"
                  >
                    Conocer Reebit
                  </Button>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          {/*IMAGEN HOME ROW*/}
          <Col
            md={{ span: 6, order: 2 }}
            xs={{ span: 12, order: 1 }}
            className="pe-5"
          >
            <Image
              className="d-inline-block align-bottom"
              src={require("./assets/img/composition.png")}
              fluid
            />
          </Col>
        </Row>
        {/*SECOND ROW PANTALLA PEQUEÑA */}
        <Row className="d-md-none d-flex">
          <Col
            xs={4}
            style={{ padding: "0px", margin: "0px" }}
            className="d-flex align-content-center justify-content-center"
          >
            <Card
              className="align-items-center"
              style={{ width: "102px", background: "none", border: "none" }}
            >
              <Card.Title
                style={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "1.563rem",
                  lineHeight: "150%",
                  color: "#1877F2",
                  textAlign: "center",
                }}
              >
                +7.000
              </Card.Title>
              <Card.Text
                className="align-text-center"
                style={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "0.875",
                  lineHeight: "120%",
                  textAlign: "center",
                }}
              >
                Usuarios registrados
              </Card.Text>
            </Card>
          </Col>
          <Col
            xs={4}
            style={{ padding: "0px", margin: "0px" }}
            className="d-flex align-content-center justify-content-center"
          >
            <Card
              className="align-items-center"
              style={{ width: "102px", background: "none", border: "none" }}
            >
              <Card.Title
                style={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "1.563rem",
                  lineHeight: "150%",
                  color: "#E83E8C",
                  textAlign: "center",
                }}
              >
                +2.000
              </Card.Title>
              <Card.Text
                className="align-items-center"
                style={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "0.875",
                  lineHeight: "120%",
                  textAlign: "center",
                }}
              >
                Servicios publicados
              </Card.Text>
            </Card>
          </Col>
          <Col
            xs={4}
            style={{ padding: "0px", margin: "0px" }}
            className="d-flex align-content-center justify-content-center"
          >
            <Card
              className="align-items-center"
              style={{ width: "102px", background: "none", border: "none" }}
            >
              <Card.Title
                style={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "1.563rem",
                  lineHeight: "150%",
                  color: "#FD9020",
                  textAlign: "center",
                }}
              >
                +1.000
              </Card.Title>
              <Card.Text
                style={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "0.875",
                  lineHeight: "120%",
                  textAlign: "center",
                }}
              >
                Clientes felices
              </Card.Text>
            </Card>
          </Col>
        </Row>
        {/*ROW ABOUT PANTALLA GRANDE */}
        <Row className="about-row" id="about">
          <Col className="pe-5 d-flex justify-content-center align-items-center d-none d-md-block">
            <Card
              style={{ width: "45.625em", background: "none", border: "none" }}
            >
              <Card.Body>
                <Card.Title>
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "3rem",
                      lineHeight: "120%",
                    }}
                  >
                    ¿Por qué Reebit?
                  </p>
                </Card.Title>
                <Card.Text>
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "1.5625rem",
                      lineHeight: "150%",
                    }}
                  >
                    Contamos con más de 7.000 servicios publicados en todo Chile
                    en más de 15 categorías. Encuentra al profesional que estás
                    buscando, hazle tus preguntas y contrátalo.
                  </p>
                </Card.Text>
                <ButtonGroup className="me-3">
                  <Button
                    id="newLandingRegistrationWhyReebitFindServiceBtn"
                    variant="primary"
                    className="service-button"
                    href={process.env.REACT_APP_REEBIT_URL}
                  >
                    Buscar servicios
                  </Button>
                </ButtonGroup>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Image
              className="d-inline-block span_padding d-none d-md-block"
              src={require("./assets/img/mobile.png")}
              fluid
            />
          </Col>
        </Row>

        {/*ROW ABOUT PANTALLA PEQUEÑA */}
        <Row className="d-flex d-md-none" style={{ paddingTop: "1em" }}>
          <Col
            className="d-flex d-md-none align-content-center justify-content-center"
            xs={12}
          >
            <Card
              className="d-flex"
              style={{
                background: "none",
                border: "none",
                width: "340px",
                height: "150px",
              }}
            >
              <Card.Body>
                <Card.Title
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "2.125rem",
                    lineHeight: "120%",
                    textAlign: "center",
                    letterSpacing: "0.0025rem",
                  }}
                >
                  ¿Por qué Reebit?
                </Card.Title>
                <Card.Text
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "1rem",
                    lineHeight: "120%",
                    textAlign: "center",
                    letterSpacing: "0.0015em",
                  }}
                >
                  Contamos con más de 7.000 servicios publicados en todo Chile
                  en más de 15 categorías. Encuentra al profesional que estás
                  buscando, hazle tus preguntas y contrátalo.
                </Card.Text>
                <ButtonGroup className="d-flex">
                  <Button
                    id="newLandingRegistrationWhyReebitFindServiceBtn"
                    variant="primary"
                    className="service-button"
                    href={process.env.REACT_APP_REEBIT_URL}
                  >
                    Buscar servicios
                  </Button>
                </ButtonGroup>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12}>
            <Image
              style={{ marginTop: "10.375rem" }}
              className="d-inline-block span_padding "
              src={require("./assets/img/mobile.png")}
              fluid
            />
          </Col>
        </Row>
      </Container>
      {/*FOOTER PANTALLA GRANDE */}
      <Nav
        className="d-none d-md-block"
        style={{
          backgroundColor: "#31353C",
          position: "absolute",
          left: "0",
          width: "100%",
        }}
      >
        <Container>
          <Row style={{ paddingTop: "3.875em" }}>
            <Col className="pe-5" md={3}>
              <Row>
                <Navbar.Brand href="#home">
                  <img
                    src={require("./assets/img/logotypes1.png")}
                    width="240"
                    height="89"
                    className="d-inline-block align-top"
                    alt="Reebit logo"
                  />
                </Navbar.Brand>
              </Row>
              <Row style={{ paddingTop: "1.1875em" }}>
                <Col md={2}>
                  <a
                    href="https://www.instagram.com/reebit.cl/"
                    className="icon-block"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i class="bi bi-instagram social-icon"></i>
                  </a>
                </Col>
                <Col md={2}>
                  <a
                    href="https://www.facebook.com/Reebit.cl/"
                    className="icon-block"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i class="bi bi-facebook social-icon"></i>{" "}
                  </a>
                </Col>
                <Col md={2}>
                  <a
                    href="https://www.linkedin.com/company/reebit/"
                    className="icon-block"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i class="bi bi-linkedin social-icon"></i>
                  </a>
                </Col>
              </Row>
            </Col>
            <Col className="flex-column" md={2}>
              <Nav.Link
                disabled
                style={{
                  fontFamily: "Poppins",
                  fontSize: "1rem",
                  fontWeight: "700",
                  lineHeight: "1.5rem",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: "white",
                }}
              >
                Accesos rápidos
              </Nav.Link>
              <Nav.Link
                className="foot-text"
                eventKey="link-1"
                style={{ color: "white" }}
                href="#home"
              >
                Inicio
              </Nav.Link>
              <Nav.Link
                className="foot-text"
                eventKey="link-2"
                style={{ color: "white" }}
                href="#about"
              >
                ¿Por qué Reebit?{" "}
              </Nav.Link>
            </Col>
            <Col className="flex-column" md={2}>
              <Nav.Link
                disabled
                style={{
                  fontFamily: "Poppins",
                  fontSize: "1rem",
                  fontWeight: "700",
                  lineHeight: "1.5rem",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: "white",
                }}
              >
                Conversemos
              </Nav.Link>
              <Nav.Link
                className="foot-text"
                eventKey="link-1"
                style={{ color: "white" }}
                href="mailto:contacto@reebit.cl"
              >
                Contacto
              </Nav.Link>
            </Col>
          </Row>
          <Row style={{ marginBottom: "3.75em", marginTop: "3.125em" }}>
            <hr style={{ color: "white" }}></hr>
            <Col style={{ marginLeft: "0px", paddingLeft: "0px" }} md={6}>
              <span style={{ color: "white" }} className="brand-text">
                Reebit® 2022
              </span>
            </Col>
            <Col md={2}>
              <a
                href={`${process.env.REACT_APP_REEBIT_URL}faqs`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span style={{ color: "white" }} className="foot-text2">
                  Preguntas frecuentes
                </span>
              </a>
            </Col>
            <Col md={2}>
              <a
                href={`${process.env.REACT_APP_REEBIT_URL}terms`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span style={{ color: "white" }} className="foot-text2">
                  Términos y condiciones
                </span>
              </a>
            </Col>
            <Col style={{ marginLeft: "0px", paddingLeft: "0px" }} md={2}>
              <a
                href={`${process.env.REACT_APP_REEBIT_URL}policies`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span
                  style={{ color: "white", textAlign: "right" }}
                  className="foot-text2"
                >
                  Políticas de privacidad
                </span>
              </a>
            </Col>
          </Row>
        </Container>
      </Nav>

      {/*FOOTER PANTALLA PEQUEÑA */}
      <Nav
        className="d-block d-md-none"
        style={{
          backgroundColor: "#31353C",
          position: "absolute",
          left: "0",
          width: "100%",
        }}
      >
        <Container>
          <Row className="flex-row" style={{ marginTop: "2rem" }}>
            <Nav.Link
              disabled
              style={{
                fontFamily: "Poppins",
                fontSize: "1rem",
                fontWeight: "700",
                lineHeight: "1.5rem",
                letterSpacing: "0em",
                textAlign: "center",
                color: "white",
              }}
            >
              Accesos rápidos
            </Nav.Link>
            <Nav.Link
              className="foot-text"
              eventKey="link-1"
              style={{ color: "white", textAlign: "center" }}
              href="#home"
            >
              Inicio
            </Nav.Link>
            <Nav.Link
              className="foot-text"
              eventKey="link-2"
              style={{ color: "white", textAlign: "center" }}
              href="#about"
            >
              ¿Por qué Reebit?{" "}
            </Nav.Link>
          </Row>
          <Row className="flex-row" style={{ paddingTop: "1.2rem" }}>
            <Nav.Link
              disabled
              style={{
                fontFamily: "Poppins",
                fontSize: "1rem",
                fontWeight: "700",
                lineHeight: "1.5rem",
                letterSpacing: "0em",
                textAlign: "center",
                color: "white",
              }}
            >
              Conversemos
            </Nav.Link>
            <Nav.Link
              className="foot-text"
              eventKey="link-1"
              style={{ color: "white", textAlign: "center" }}
              href="mailto:contacto@reebit.cl"
            >
              Contacto
            </Nav.Link>
          </Row>
          <Row style={{ marginTop: "1.5rem" }}>
            <Nav.Link
              className="foot-text"
              href={`${process.env.REACT_APP_REEBIT_URL}faqs`}
              target="_blank"
              style={{ color: "white", textAlign: "center" }}
            >
              Preguntas frecuentes
            </Nav.Link>
            <Nav.Link
              href={`${process.env.REACT_APP_REEBIT_URL}terms`}
              style={{ color: "white", textAlign: "center" }}
              target="_blank"
              className="foot-text"
            >
              Términos y condiciones
            </Nav.Link>
            <Nav.Link
              href={`${process.env.REACT_APP_REEBIT_URL}policies`}
              target="_blank"
              style={{ color: "white", textAlign: "center" }}
              className="foot-text"
            >
              Políticas de privacidad
            </Nav.Link>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center">
              <ListGroup horizontal>
                <ListGroup.Item
                  style={{ backgroundColor: "#31353C", border: "none" }}
                >
                  <a
                    href="https://www.instagram.com/reebit.cl/"
                    className="icon-block"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i class="bi bi-instagram social-icon"></i>
                  </a>
                </ListGroup.Item>
                <ListGroup.Item
                  style={{ backgroundColor: "#31353C", border: "none" }}
                >
                  <a
                    href="https://www.facebook.com/Reebit.cl/"
                    className="icon-block"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i class="bi bi-facebook social-icon"></i>
                  </a>
                </ListGroup.Item>
                <ListGroup.Item
                  style={{ backgroundColor: "#31353C", border: "none" }}
                >
                  <a
                    href="https://www.linkedin.com/company/reebit/"
                    className="icon-block"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i class="bi bi-linkedin social-icon"></i>
                  </a>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
          <Row style={{ paddingTop: "2em" }}>
            <Col className="d-flex justify-content-center">
              <Navbar.Brand href="#home">
                <img
                  src={require("./assets/img/logotypes1.png")}
                  width="240"
                  height="89"
                  className="d-inline-block align-top"
                  alt="Reebit logo"
                />
              </Navbar.Brand>
            </Col>
          </Row>
          <Row>
            <Col>
              <hr style={{ color: "white" }}></hr>
            </Col>
          </Row>
          <Row className="flex-row" style={{ paddingBottom: "1em" }}>
            <Col className="d-flex justify-content-center">
              <span
                style={{
                  color: "white",
                  textAlign: "center",
                }}
                className="brand-text"
              >
                Reebit® 2022
              </span>
            </Col>
          </Row>
        </Container>
      </Nav>
    </Container>
  );
}

export default App;
